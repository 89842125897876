export enum Browser {
  chrome,
  firefox,
  safari,
}

export function detectBrowser(): Browser {
  const userAgent = navigator.userAgent.toLowerCase();
  // Important: check Chrome first, as its user agent also contains 'safari'
  if (userAgent.indexOf("chrome") !== -1) return Browser.chrome;
  else if (userAgent.indexOf("safari") !== -1) return Browser.safari;
  else return Browser.firefox;
}
