export function slugify(expression: string): string {
  expression = expression.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  expression = expression.toLowerCase();

  // Remove accents, swap ñ for n, etc
  const from: string =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to: string =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (let i = 0, l = from.length; i < l; i++) {
    expression = expression.replace(
      new RegExp(from.charAt(i), "g"),
      to.charAt(i)
    );
  }

  // Remove invalid chars
  expression = expression
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return expression;
}
