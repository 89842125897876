import * as React from "react";
import styled from "styled-components";
import { theme } from "../../theme/theme";
import { slugify } from "../../utils/slugify";

const CheckboxInput = styled.input`
  position: absolute;
  margin: 0;
  opacity: 0;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 1.875em;
  cursor: pointer;
  user-select: none;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0.1875em;
    left: 0;
    display: block;
    height: 1.25em;
    width: 1.25em;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &:before {
    border: 2px solid ${theme.palette.common.black};
    background-color: ${theme.palette.common.white};
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    ${CheckboxInput}:checked + & {
      border-color: ${theme.palette.primary.darker};
    }
  }

  &:after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3e%3cpath fill='${theme.palette.primary.darker}' d='M10 0C4.46667 0 0 4.46667 0 10C0 15.5333 4.46667 20 10 20C15.5333 20 20 15.5333 20 10C20 4.46667 15.5333 0 10 0ZM10.9333 14.6C10.5333 15.7333 9 16.0667 8.2 15.2C6 12.8667 4 11.4667 5.46667 10C6.86667 8.66667 8.2 10.4 8.86667 11.1333C10.8667 7.26667 12.6667 4.73333 14.3333 6.06667C16.4667 7.73333 13 9.2 10.9333 14.6Z'/%3e%3c/svg%3e");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 1.25em 1.25em;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    ${CheckboxInput}:checked + & {
      opacity: 1;
    }
  }
`;

export const Checkbox = ({ label, value, onChange }) => {
  const slug = slugify(label);
  return (
    <>
      <CheckboxInput
        id={slug}
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <Label for={slug} className="emphasized-text">
        {label}
      </Label>
    </>
  );
};
