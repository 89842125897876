import * as React from "react";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Reset } from "styled-reset";
import { ThemeProvider } from "styled-components";
import { useIntl } from "gatsby-plugin-intl";

import { sendUninstallationReason } from "../../api/send-uninstallation-reason";
import { GoogleFormCheckboxModel } from "../../models/google-form-checkbox-model";

import { theme } from "../../theme/theme";
import FontFaces from "../../theme/fonts/font-faces";
import GlobalStyle, { gtMDMediaQuery } from "../../theme/global";

import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { HalfCenteredRow } from "../../components/common/layout";
import { Button } from "../../components/common/button";
import { Checkbox } from "../../components/common/checkbox";
import { getPageTitle } from "../../Locales";
import { LocalStorageKey } from "../../lib/localStorage";

const DEV_STACK_EVENT_LOGGING_URL =
  "https://ttwgkhkd82.execute-api.eu-west-1.amazonaws.com/ingest-user-events";
const PROD_STACK_EVENT_LOGGING_URL =
  "https://5lcadbj7za.execute-api.eu-west-1.amazonaws.com/ingest-user-events";
const AMPLITUDE_API_KEY = "bb40330abc74b9e9b8a4f95a086c3416";

const Wrapper = styled.section`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 6.25em 0;
`;

const PreHeading = styled.p`
  text-align: center;
  margin-bottom: 1em;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`;

const Indication = styled.p`
  display: block;
  text-align: center;
  margin-bottom: 1em;

  &:not(:first-child) {
    margin-top: 2em;
  }
`;

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0.625em;
  margin: 0;
  border: 2px solid ${theme.palette.common.black};
  border-radius: 0.625em;
  background-color: ${theme.palette.common.white};
  font-family: inherit;
  font-size: inherit;
  resize: none;

  &::placeholder {
    color: ${theme.palette.common.black};
    opacity: 0.5;
  }

  ${gtMDMediaQuery} {
    &.emphasized-text {
      font-size: ${theme.fonts.emphasized.size};
    }
  }

  &.emphasized-text:not(:first-child) {
    margin-top: 0;
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 2em auto 0;
`;

// googleFormOption should match perfectly one of the options of the Google Forms page
// Any typo will prevent the corresponding entry from being recorded in Google Forms
type CheckboxOption = { googleFormOption: string; displayedOptionId: string };

//TODO: externalise this
const CHECKBOX_OPTIONS: CheckboxOption[] = [
  {
    googleFormOption: "L’extension apparaît trop souvent",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.extensionAppearsTooOften",
  },
  {
    googleFormOption: "Il n’y a pas assez d’enseignes",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.notEnoughBrands",
  },
  {
    googleFormOption: "Mes achats ne sont pas pas pris en compte", // text at form creation
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.purchasesNotTakenIntoAccount",
  },
  {
    googleFormOption: "L’extension ne marche pas, il y a des bugs",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.extensionBugs",
  },
  {
    googleFormOption: "Je ne fais pas d’achats sur cet ordinateur",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.noPurchaseOnThisComputer",
  },
  {
    googleFormOption: "Je ne gagne pas assez de points",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.notEnoughCashback",
  },
  {
    googleFormOption: "Mes points n’arrivent pas assez vite",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.cashbackNotFastEnough",
  },
  {
    googleFormOption: "J’utilise une autre extension de cashback",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.useDifferentCashbackExtension",
  },
  {
    googleFormOption: "Je ne comprends pas l’utilité du cashback",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.doNotUnderstandTheIdeaOfCashback",
  },
  {
    googleFormOption: "Par soucis de confidentialité",
    displayedOptionId:
      "uninstallationForm.form.formCheckboxValues.privacyReasons",
  },
];

function useCheckboxValues(): [
  GoogleFormCheckboxModel[],
  (index: number) => void
] {
  const intl = useIntl();
  const [checkboxValues, setCheckboxValues] = React.useState<
    GoogleFormCheckboxModel[]
  >(
    CHECKBOX_OPTIONS.map(({ googleFormOption, displayedOptionId }) => ({
      googleFormOption,
      displayedOption: intl.formatMessage({
        id: displayedOptionId,
      }),
      isChecked: false,
    }))
  );
  const toggleCheckboxByIndex = (index: number) => {
    setCheckboxValues(
      checkboxValues.map((value, newIndex) => ({
        ...value,
        isChecked: index === newIndex ? !value.isChecked : value.isChecked,
      }))
    );
  };
  return [checkboxValues, toggleCheckboxByIndex];
}

interface FormModel {
  userId: string | null;
  onSendFormResponse: () => void;
}

function Form({ userId, onSendFormResponse }: FormModel) {
  const intl = useIntl();
  const [checkboxValues, toggleCheckboxByIndex] = useCheckboxValues();
  const [additionalFeedback, setAdditionalFeedback] = React.useState<
    string | undefined
  >(undefined);
  const isFormResponseValid =
    checkboxValues.some(({ isChecked }) => isChecked) || !!additionalFeedback;
  return (
    <form>
      {checkboxValues.map(({ displayedOption, isChecked }, index) => (
        <Checkbox
          key={index}
          label={displayedOption}
          value={isChecked}
          onChange={function name(_params: any) {
            toggleCheckboxByIndex(index);
          }}
        />
      ))}
      <br />

      <Indication as="label" className="text">
        {intl.formatMessage({
          id: "uninstallationForm.form.otherReason",
        })}
      </Indication>
      <Textarea
        className="emphasized-text"
        placeholder={intl.formatMessage({
          id: "uninstallationForm.form.otherReasonPlaceholder",
        })}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          setAdditionalFeedback(event.currentTarget.value);
        }}
      />

      {isFormResponseValid}

      <StyledButton
        as="input"
        type="button"
        value={intl.formatMessage({
          id: "uninstallationForm.form.submitButtonLabel",
        })}
        disabled={!isFormResponseValid}
        bigger
        onClick={async () => {
          if (isFormResponseValid) {
            await sendUninstallationReason({
              checkboxValues,
              additionalFeedback,
              userId,
            });
            onSendFormResponse();
          }
        }}
      />
    </form>
  );
}

const BrowserExtensionUninstallationForm = ({ path }: { path: string }) => {
  const intl = useIntl();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const userId: string | null = urlSearchParams.get("userId");
  useLogUninstallEvent(userId, urlSearchParams);
  const [hasSentFormResponse, setHasSentFormResponse] =
    React.useState<boolean>(false);
  const onSendFormResponse = () => setHasSentFormResponse(true);
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <main>
        <Helmet>
          <title>
            {getPageTitle(
              intl.formatMessage({ id: "uninstallationForm.pageTitle" })
            )}
          </title>
          <style type="text/css">{FontFaces}</style>
        </Helmet>
        <Header />
        <Wrapper>
          <HalfCenteredRow>
            {!hasSentFormResponse ? (
              <>
                <PreHeading className="heading">
                  {intl.formatMessage({
                    id: "uninstallationForm.form.preTitle",
                  })}
                </PreHeading>
                <Heading className="small-heading">
                  {intl.formatMessage({
                    id: "uninstallationForm.form.title",
                  })}
                </Heading>
                <Indication className="text">
                  {intl.formatMessage({
                    id: "uninstallationForm.form.indication",
                  })}
                </Indication>
                <Form {...{ userId, onSendFormResponse }} />
              </>
            ) : (
              <>
                <Heading className="heading">
                  {intl.formatMessage({
                    id: "uninstallationForm.thanks.title",
                  })}
                </Heading>
                <Indication className="small-heading">
                  {intl.formatMessage({
                    id: "uninstallationForm.thanks.indication",
                  })}
                </Indication>
              </>
            )}
          </HalfCenteredRow>
        </Wrapper>
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default BrowserExtensionUninstallationForm;

type EventLoggingParameters = {
  userId: string;
  devStackMode: boolean;
  deviceId: string;
  os: string;
  osVersion: string;
  userRegion: string;
  versionNumberKeyName: string;
  versionNumber: string;
  userEventPlatform: string;
  amplitudePlatform: string;
};

function useLogUninstallEvent(
  userId: string | null,
  urlSearchParams: URLSearchParams
) {
  React.useEffect(() => {
    if (shouldRateLimitLogUninstallEvent()) {
      return;
    }
    if (userId) {
      const params: EventLoggingParameters = {
        userId,
        devStackMode: urlSearchParams.get("isDev") === "true",
        deviceId: urlSearchParams.get("deviceId") || "",
        os: urlSearchParams.get("os") || "",
        osVersion: urlSearchParams.get("osVersion") || "",
        userRegion: urlSearchParams.get("userRegion") || "",
        versionNumberKeyName: urlSearchParams.get("versionNumberKeyName") || "",
        versionNumber: urlSearchParams.get("versionNumber") || "",
        userEventPlatform: urlSearchParams.get("userEventPlatform") || "",
        amplitudePlatform: urlSearchParams.get("amplitudePlatform") || "",
      };
      logUninstalledBrowserExtensionUserEvent(params);
      sendUninstalledBrowserExtensionAmplitudeEvent(params);
    }
  }, [userId, urlSearchParams]);
}

const DEFAULT_DELAY_BETWEEN_CONSECUTIVE_EVENT_LOGS_IN_SECONDS = 60;

function shouldRateLimitLogUninstallEvent(
  minimumDelayBetweenEventLoggingInSeconds: number = DEFAULT_DELAY_BETWEEN_CONSECUTIVE_EVENT_LOGS_IN_SECONDS
): boolean {
  const lastLogTimestamp = localStorage.getItem(
    LocalStorageKey.lastUninstalledBrowserExtensionEventLoggedTimestampMs
  );
  if (
    !lastLogTimestamp ||
    Number(lastLogTimestamp) + minimumDelayBetweenEventLoggingInSeconds * 1000 <
      Date.now()
  ) {
    localStorage.setItem(
      LocalStorageKey.lastUninstalledBrowserExtensionEventLoggedTimestampMs,
      Date.now().toString()
    );
    return false;
  }
  return true;
}

async function logUninstalledBrowserExtensionUserEvent({
  versionNumberKeyName,
  versionNumber,
  userEventPlatform,
  deviceId,
  os,
  osVersion,
  userId,
  devStackMode,
}: EventLoggingParameters) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const payload = {
    [versionNumberKeyName]: versionNumber,
    platform: userEventPlatform,
    deviceId: deviceId,
    os: os,
    osVersion: osVersion,
  };
  const stringifiedPayload = JSON.stringify(payload);
  const events = [
    {
      type: "uninstalledBrowserExtension",
      payload: stringifiedPayload,
      active: true,
      timestamp: Number((Date.now() / 1000).toFixed(3)),
    },
  ];
  await fetch(
    devStackMode ? DEV_STACK_EVENT_LOGGING_URL : PROD_STACK_EVENT_LOGGING_URL,
    {
      method: "POST",
      headers,
      body: JSON.stringify({ userId: userId, events }),
    }
  ).catch(console.error);
}

async function sendUninstalledBrowserExtensionAmplitudeEvent({
  deviceId,
  os,
  osVersion,
  userId,
  userRegion,
  versionNumberKeyName,
  versionNumber,
  amplitudePlatform,
}: EventLoggingParameters) {
  const timestampInMs = Date.now();
  const payload = {
    device_id: deviceId,
    os_name: os,
    os_version: osVersion,
    user_id: userId,
    user_properties: {
      region: userRegion,
      [versionNumberKeyName]: versionNumber,
    },
    event_type: "Navigation - Uninstalled Browser Extension",
    time: timestampInMs,
    platform: amplitudePlatform,
  };
  await fetch(
    `https://api.amplitude.com/httpapi?api_key=${AMPLITUDE_API_KEY}` +
      `&event=${encodeURIComponent(JSON.stringify([payload]))}`
  ).catch((error) => console.error(`Amplitude: Error ${error.message}`));
}
