import { GoogleFormCheckboxModel } from "../models/google-form-checkbox-model";
import { Browser, detectBrowser } from "../utils/detect-browser";

const FORM_URL = process.env.GOOGLE_FORM_UNINSTALLATION_REASON;

enum FormEntryId {
  checkboxValues = +process.env.GOOGLE_FORM_UNINSTALLATION_REASON_CHECKBOX_VALUES,
  additionalFeedback = +process.env.GOOGLE_FORM_UNINSTALLATION_REASON_ADDITIONAL_FEEDBACK,
  platform = +process.env.GOOGLE_FORM_UNINSTALLATION_REASON_PLATFORM,
  userId = +process.env.GOOGLE_FORM_UNINSTALLATION_REASON_USER_ID,
}

type SendFormResponseInputModel = {
  checkboxValues: GoogleFormCheckboxModel[];
  additionalFeedback: string | undefined;
  userId: string | null;
};

export async function sendUninstallationReason(
  input: SendFormResponseInputModel
) {
  const urlParams: string[] = input.checkboxValues
    .filter(({ isChecked }) => isChecked)
    .map(({ googleFormOption }) => {
      return `entry.${FormEntryId.checkboxValues}=${googleFormOption}`;
    });

  const browserKind = detectBrowser();

  let platform: "Chrome" | "Firefox" | "Safari";

  switch (browserKind) {
    case Browser.safari:
      platform = "Safari";
      break;
    case Browser.firefox:
      platform = "Firefox";
      break;
    default:
      platform = "Chrome";
  }

  urlParams.push(`entry.${FormEntryId.platform}=${platform}`);

  if (input.additionalFeedback) {
    urlParams.push(
      `entry.${FormEntryId.additionalFeedback}=${input.additionalFeedback}`
    );
  }

  if (input.userId) {
    urlParams.push(`entry.${FormEntryId.userId}=${input.userId}`);
  }

  const url = encodeURI(`${FORM_URL}?${urlParams.join("&")}`);

  await fetch(url, { method: "POST", mode: "no-cors" });
}
